body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Whatsapp */
/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 56px;
  height: 56px;
  bottom: 60px;
  left: 20px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line_through {
  text-decoration: line-through;
}

ul {
  padding-left: 15px;
}
ul li {
  margin-left: 5px;
}
#tabellaStampa {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
#tabellaStampa tbody > tr {
  padding-bottom: 20px;
}
#tabellaStampa tbody > tr:nth-child(odd) {
  background-color: #fff;
}
#tabellaStampa tbody > tr:nth-child(even) {
  background-color: #ededed;
}
#tabellaStampa th, #tabellaStampa td {
  border: 1px solid #000;
  padding: 0 10px;
}

/* Dopo aver convertito la tabella in div */
@media print {
  div {
    break-inside: avoid;
  }
}

.selectToolbar.MuiFormControl-root {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.selectToolbar .MuiSelect-select {
  padding: 3px !important;
}